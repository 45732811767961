import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import { pagesLinks } from "../../../pages-data/_V2/common/links"
import styles from "./styles.module.scss"

export default {
	desktopTitle: (
		<span>Программа автоматизации паба&nbsp;</span>
	),
	price: "от 2490 ₽/мес",
	subTitle: (
		<p>
			Quick Resto&nbsp;&mdash; кассовая программа для пабов, с&nbsp;системой
			учёта алкоголя, инструментами для привлечения гостей и&nbsp;контроля
			персонала. Кассовый терминал Quick Resto позволяет принимать
			до&nbsp;10&nbsp;заказов в&nbsp;минуту.
		</p>
	),
	figure: (
		<StaticImage
			className={styles.image}
			src="./assets/pab-presentation.png"
			alt="автоматизация паба"
			objectFit="contain"
			objectPosition="center"
			placeholder="blurred"
			quality={90}
		/>
	),
	cta: pagesLinks.registration.text,
	ctaLink: pagesLinks.registration,
	desktopCtaDetails:
		"За 10 минут, без дорогого оборудования, с удобным интерфейсом. И две недели бесплатно.",
}
